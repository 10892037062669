<template lang="pug">
.brand-wrapper
  .container-fluid
    .row
      .col-6.align-self-center
        om-heading.mt-1(h1) {{ $t('analyticsPage.title') }}

    .row.d-flex.justify-content-between.align-items-center.selectors.mt-5
      .domain-selector.col-auto.d-flex.align-items-center
        img.favicon(:src="faviconUrl")
        om-select#campaignAnalyticsDomain.domain-selector.ml-1(
          size="small"
          v-model="selectedDomain"
          :options="domains"
          optionKey="_id"
          optionText="domain"
          style="min-width: 190px"
        )
      .d-flex(v-if="!noOMDetected")
        .col-auto
          om-select#campaignAnalyticsDevice(
            size="small"
            v-model="selectedDevice"
            :options="devices"
            :label="$t('devices')"
            style="min-width: 180px"
          )
        .col-auto
          om-period-select#campaignAnalyticsDateRange(
            :options="dateRangeOptions"
            :label="$t('analyticsPage.dateRange')"
            :locale="$i18n.locale"
            @input="dateRangeChange(selectedDateRange, $event)"
            :value="selectedDateRange"
            :minDate="minimumSelectableDate"
            :maxDate="maximumSelectableDate"
            size="small"
          )
    .no-code-detected.d-flex.mt-4(v-if="noOMDetected")
      .right-shape
        img(:src="require('@/assets/admin/img/analytics-right-shape.svg')")
      .explanation
        .title {{ $t('analyticsPage.noCodeDetected.title') }}
        .description.mt-5 {{ $t('analyticsPage.noCodeDetected.description') }}
        om-link.mt-3(primary @click="learnMore()") {{ $t('analyticsPage.noCodeDetected.learnMore') }}
          UilAngleRight(width="1.5em" height="1.5em")
      .image
        img(src="@/assets/admin/img/analytics-skeleton.png")
    template(v-if="!noOMDetected")
      template(v-if="campaignReportError || goalsError || topLevelDomainError")
        .brand-box.brand-box-gray.text-center.pb-5.flex-wrap.justify-content-center.flex-column.general-error
          img(src="@/assets/empty-state/monk_magnifying_hat.svg")
          .w-100.pt-5.font-size-1--5.font-weight-bold
            span.analytics-error-message(v-html="$t('analyticsPage.generalError')")
      template(v-else)
        .ga4-notification.d-flex.mt-4.p-2(v-if="!isShopifyOrShoprenter")
          UilExclamationCircle(color="#F0C920")
          om-body-text.ml-2(bt400sm v-html="$t('analyticsPage.ga4Notification')")
        .top-level-error.d-flex.mb-4.mt-4.p-2(v-if="noDeviceData")
          UilExclamationCircle(color="#F0C920")
          om-body-text.ml-2(bt400sm) {{ $t('analyticsPage.noDeviceSpecificData') }}
        .row.total-analytics.justify-content-between.d-flex.mt-5(v-else)
          .col-4.col-xl.mb-xl-0(v-for="(total, key) in topLevelDataForDomain")
            .total-analytics-container
              .total-analytics-title
                om-tooltip(:placement="'auto'" :delay="300")
                  div(v-html="$t(`analyticsPage.top-level-explanation-${key}`)")
                  template(slot="trigger")
                    .title {{ $t(`analyticsPage.${key}`) }}
              skeleton-loader(:rows="1" :bottomLine="false" v-if="loadingTopLevelData")
              template(v-else)
                .total-analytics-total(v-if="simpleValues.includes(key)")
                  template(v-if="noDeviceData") N/A
                  campaign-report-value(v-else :value="total" :columnKey="key")

                .total-analytics-total(v-else)
                  template(v-if="total === null || noDeviceData") N/A
                  template(v-else-if="isNullValue(total)") 0
                  template(
                    v-else
                    v-for="(value, currency, index) in getAdditionalCurrencies(total)"
                  )
                    campaign-report-value(
                      :value="value"
                      :columnKey="key"
                      :currency="currency"
                      :secondary="index !== 0"
                    )
        skeleton-loader.skeleton-loader(
          :rows="5"
          v-if="loadingCampaignReport || loadingGoals"
          :type="'analytics'"
        )
        template(v-else-if="analytics.length === 0")
          .brand-box.brand-box-gray.text-center.pt-5.pb-5.flex-wrap.justify-content-center
            .pt-5.pb-5
              img(src="@/assets/empty-state/monk-magnifying.svg")
            .w-100.pt-5.font-size-1--5.font-weight-bold
              span.analytics-error-message(v-html="$t('analyticsPage.noReports')")

        analytics-table-multi-source(
          :analytics="analytics"
          :goalConversions="goalConversionsPerDomain"
          :chanceToWin="chanceToWinPerDomain"
          :tabs="tabs"
          :deviceType="deviceType"
          :loadingGoal="loadingGoal"
          :goalError="goalError"
          :loadingChanceToWin="loadingChanceToWin"
          :chanceToWinError="chanceToWinError"
          :selectedTab="selectedTab"
          :noDeviceData="noDeviceData"
          :lastUpdated="lastUpdatedAnalytics"
          @selectTab="changeTab"
          v-else
        )
</template>

<script>
  import { mapGetters } from 'vuex';
  import moment from 'moment';
  import campaignReports from '@/mixins/campaignReports';
  import AnalyticsTableMultiSource from '@/components/AnalyticsTableMultiSource.vue';
  import SkeletonLoader from '@/components/SkeletonLoader/SkeletonLoader.vue';
  import CampaignReportValue from '@/components/CampaignReportValue.vue';
  import Vue from 'vue';
  import {
    UilExclamationTriangle,
    UilExclamationCircle,
    UilAngleRight,
  } from '@iconscout/vue-unicons';
  import { lsStore, lsRetrieve } from '@/helpers/localStorage';
  import GET_GOALS from '@/graphql/GetGoals.gql';
  import GET_GOAL_GROUPED_BY_DEVICE_TYPE from '@/graphql/GetGoalGroupedByDeviceType.gql';
  import { purifyDomain } from '@/util';
  import { track } from '@/services/xray';

  export default {
    components: {
      AnalyticsTableMultiSource,
      SkeletonLoader,
      CampaignReportValue,
      UilExclamationTriangle,
      UilExclamationCircle,
      UilAngleRight,
    },
    mixins: [campaignReports],
    data() {
      const devices = [
        { key: 'desktop_and_mobile', value: this.$t('analyticsPage.devices.desktop_and_mobile') },
        { key: 'desktop', value: this.$t('analyticsPage.devices.desktop') },
        { key: 'mobile', value: this.$t('analyticsPage.devices.mobile') },
      ];
      const dateRangeOptions = [
        {
          key: 'yesterday',
          value: this.$t('analyticsPage.yesterday'),
          interval: {
            from: moment.utc().subtract(1, 'days').startOf('day'),
            to: moment.utc().subtract(1, 'days').endOf('day'),
          },
        },
        {
          key: 'past7days',
          value: this.$t('analyticsPage.last7days'),
          interval: {
            from: moment.utc().subtract(6, 'days').startOf('day'),
            to: moment.utc().endOf('day'),
          },
        },
        {
          key: 'last30days',
          value: this.$t('analyticsPage.last30days'),
          interval: {
            from: moment.utc().subtract(29, 'days').startOf('day'),
            to: moment.utc().endOf('day'),
          },
        },
        {
          key: 'lastMonth',
          value: this.$t('analyticsPage.lastMonth'),
          interval: {
            from: moment.utc().subtract(1, 'month').startOf('month'),
            to: moment.utc().subtract(1, 'month').endOf('month'),
          },
        },
      ];
      return {
        loadingTopLevelData: true,
        topLevelDomainError: null,
        topLevelDomainData: {},
        chanceToWin: {},
        goalConversions: {},
        loadingCampaignReport: true,
        campaignReportError: false,
        loadingGoals: true,
        goalsError: false,
        loadingGoal: false,
        goalError: false,
        loadingChanceToWin: false,
        chanceToWinError: false,
        devices,
        dateRangeOptions,
        filter: {
          dateRange: dateRangeOptions[2].key,
          device: devices[0].key,
          endDate: null,
          startDate: null,
          domainId: null,
          tab: 'campaignConversion',
        },
        rawReport: [],
        sorting: { field: 'createdAt', direction: -1 },
        requestDateFormat: 'YYYY-MM-DD',
        customRange: '',
        selectedDomain: null,
        DEFAULT_DOMAIN_IN_ANALYTICS_KEY: 'defaultDomainInAnalytics',
        tabs: [{ key: 'campaignConversion', text: this.$t('analyticsPage.campaignConversion') }],
        mappedReportCampaignsForChanceToWin: null,
        deviceTypeFromDate: moment('2024-02-21'),
      };
    },
    computed: {
      ...mapGetters([
        'hasAccountFeature',
        'domains',
        'isShoprenterDomain',
        'isShopifyDomain',
        'domainRequestStatus',
        'getLocale',
      ]),
      accountId() {
        return this.$route.params.userId;
      },
      deviceType() {
        return this.filter.device === 'desktop_and_mobile' ? 'allType' : this.filter.device;
      },
      selectedTab: {
        get() {
          if (!this.tabs.some((goal) => goal.key === this.filter.tab)) {
            return 'campaignConversion';
          }
          return this.filter.tab;
        },
        set(selected) {
          this.filter.tab = selected;
          return selected;
        },
      },
      selectedDevice: {
        get() {
          return this.devices.find((device) => device.key === this.filter.device);
        },
        set(selected) {
          this.filter.device = selected.key;
          return selected;
        },
      },
      selectedDateRange() {
        if (this.filter.dateRange === 'custom') {
          return this.customRange;
        }
        return this.dateRangeOptions.find((range) => range.key === this.filter.dateRange);
      },
      minimumSelectableDate() {
        return moment.utc().subtract(6, 'month').format('YYYY-MM-DD');
      },
      maximumSelectableDate() {
        return moment.utc().format('YYYY-MM-DD');
      },
      topLevelDataForDomain() {
        const selectedDomainData = this.topLevelDomainData?.[this.selectedDomain?._id] || [];

        const { visitors, visitorsWithOrders, conversionRate, totalOrders, revenue, aov } =
          selectedDomainData;

        return {
          visitors: visitors?.[this.deviceType] ?? 0,
          visitorsWithOrders: visitorsWithOrders?.[this.deviceType] ?? 0,
          conversionRate: conversionRate?.[this.deviceType] ?? 'N/A',
          totalOrdersHeader: totalOrders?.[this.deviceType] ?? 0,
          revenue: revenue?.[this.deviceType] ?? 0,
          aov: aov?.[this.deviceType] ?? null,
        };
      },
      startDate() {
        if (this.filter.dateRange === 'custom') {
          return this.filter.startDate;
        }
        const range = this.dateRangeOptions.find((range) => range.key === this.filter.dateRange);
        return moment(range.interval.from).format(this.requestDateFormat);
      },
      endDate() {
        if (this.filter.dateRange === 'custom') {
          return this.filter.endDate;
        }
        const range = this.dateRangeOptions.find((range) => range.key === this.filter.dateRange);
        return moment(range.interval.to).format(this.requestDateFormat);
      },
      faviconUrl() {
        return this.selectedDomain
          ? `https://www.google.com/s2/favicons?domain=${this.selectedDomain.domain}&sz=32`
          : '';
      },
      isShopifyOrShoprenter() {
        return (
          this.isShopifyDomain(this.selectedDomain?.domain, this.selectedDomain?.shopId) ||
          this.isShoprenterDomain(this.selectedDomain?.domain)
        );
      },
      chanceToWinForDomainAndTab() {
        return this.chanceToWin[this.selectedDomain._id]?.[this.selectedTab];
      },
      goalConversionsPerDomain() {
        return this.goalConversions[this.selectedDomain._id] || {};
      },
      chanceToWinPerDomain() {
        return this.chanceToWin[this.selectedDomain._id] || {};
      },
      analytics() {
        const analyticsForDomain = this.rawReport?.domains?.find((domain) => {
          return purifyDomain(domain.name) === purifyDomain(this.selectedDomain.domain);
        });
        return analyticsForDomain?.campaigns || [];
      },
      includesDateWithoutDeviceType() {
        const intervalStart = moment(this.selectedDateRange.interval.from);

        if (intervalStart.isBefore(this.deviceTypeFromDate)) {
          return true;
        }
        return false;
      },
      noDeviceData() {
        return this.includesDateWithoutDeviceType && this.deviceType !== 'allType';
      },
      lastUpdatedAnalytics() {
        let dataAggregatedUpTo;
        const now = moment.utc();
        const lastFinishedHour = now.startOf('hour').toISOString();
        const secondLastFinishedHour = now.subtract(1, 'hours').startOf('hour').toISOString();

        if (new Date(this.rawReport.lastUpdated) >= new Date(lastFinishedHour)) {
          dataAggregatedUpTo = lastFinishedHour;
        } else {
          dataAggregatedUpTo = secondLastFinishedHour;
        }

        return `${this.$t('analyticsPage.lastUpdated')}${moment(dataAggregatedUpTo).fromNow()}`;
      },
      noOMDetected() {
        return this.domainRequestStatus(this.selectedDomain?.domain) === 'no_code';
      },
    },
    watch: {
      selectedDomain: {
        async handler(newValue, oldValue) {
          this.filter.domainId = newValue?._id;
          if (oldValue && !this.topLevelDomainData[newValue?._id]) {
            this.getTopLevelDomainData(newValue._id);
          }
          await this.getGoals(this.selectedDomain._id);
          if (this.topLevelDomainData[newValue?._id]) {
            this.topLevelDomainError = null;
          }

          this.saveDefaultDomainToLS(this.selectedDomain._id);

          if (this.selectedTab !== 'campaignConversion') {
            await this.getGoalTabData(this.selectedTab);
          }
        },
      },
      filter: {
        handler() {
          this.updateBrowserUrl();
        },
        deep: true,
      },
    },
    async mounted() {
      this.getSelectedDomain();
      this.handleUrlQueryParams();

      await Promise.all([
        this.fetchCampaignReport(),
        this.getTopLevelDomainData(),
        this.getGoals(this.selectedDomain._id),
      ]);

      if (this.selectedTab !== 'campaignConversion') {
        await this.getGoalTabData(this.selectedTab);
      }
    },
    methods: {
      getSelectedDomain() {
        const defaultDomainFromLs = this.getDefaultDomainFromLS();
        if (defaultDomainFromLs) {
          this.selectedDomain = this.domains.find((domain) => domain._id === defaultDomainFromLs);
        } else {
          this.selectedDomain = this.domains[0];
          this.saveDefaultDomainToLS(this.selectedDomain._id);
        }
        this.filter.domainId = this.selectedDomain._id;
      },
      handleUrlQueryParams() {
        const { tab, dateRange, device, endDate, startDate, domain } = this.$route.query;
        const queryKeys = {
          tab,
          dateRange,
          device,
          endDate,
          startDate,
          domain,
        };

        if (dateRange === 'custom') {
          this.customRange = {
            key: 'custom',
            value: this.$t('analyticsPage.custom'),
            interval: {
              from: new Date(startDate).toISOString(),
              to: new Date(endDate).toISOString(),
            },
          };
        }

        if (Object.values(queryKeys).some(Boolean)) {
          for (const [key, value] of Object.entries(queryKeys)) {
            if (typeof value !== 'undefined') {
              this.filter = {
                ...this.filter,
                [key]: JSON.parse(JSON.stringify(value)),
              };
            }
          }
        }
      },
      async getTopLevelDomainData() {
        this.loadingTopLevelData = true;
        this.topLevelDomainError = null;
        try {
          const topLevelData = (
            await this.$axios.get(
              `/reports/get-top-level-domain-data?start_date=${this.startDate}&end_date=${this.endDate}&domain_id=${this.selectedDomain._id}`,
            )
          ).data;
          Vue.set(this.topLevelDomainData, this.selectedDomain._id, topLevelData);
        } catch (e) {
          if (e.response?.data?.startsWith('USER_NOT_FOUND')) {
            this.topLevelDomainError = 'USER_NOT_FOUND';
          } else {
            this.topLevelDomainError = 'OTHER_ERROR';
          }
          track('analyticsError', {
            type: 'topLevelDomainError',
            domainId: this.selectedDomain._id,
            startDate: this.startDate,
            endDate: this.endDate,
          });
        }
        this.loadingTopLevelData = false;
      },
      async getChanceToWin(campaigns, domain, tab) {
        if (!campaigns?.length) return;
        this.loadingChanceToWin = true;
        this.chanceToWinError = false;
        try {
          const chanceToWin = (await this.$axios.post('/reports/chance-to-win', { campaigns }))
            .data;
          if (!this.chanceToWin[domain]) {
            this.chanceToWin[domain] = {};
          }
          const chanceTowinForSelectedDomain = {
            ...this.chanceToWin[domain],
            [tab]: chanceToWin,
          };
          this.chanceToWin = {
            ...this.chanceToWin,
            [domain]: chanceTowinForSelectedDomain,
          };
        } catch (e) {
          this.chanceToWinError = true;
        }
        this.loadingChanceToWin = false;
      },
      mapReportForChanceToWin() {
        const campaignsToCalculateChanceToWin =
          this.rawReport.domains?.find(
            (domain) => purifyDomain(domain.name) === purifyDomain(this.selectedDomain?.domain),
          )?.campaigns || [];
        return campaignsToCalculateChanceToWin
          ?.filter((c) => c.variants.length > 1)
          ?.map((campaign) => {
            return {
              id: campaign.id,
              variants: campaign.variants.map((variant) => {
                return {
                  id: variant.id,
                  impressions: variant.impressionCount,
                  conversions: variant.conversionCount,
                };
              }),
            };
          });
      },
      mapReportAndGoalForChanceToWin(domainId, tab) {
        const selectedDomain = this.domains.find((d) => d._id === domainId)?.domain;
        const campaignsToCalculateChanceToWin =
          this.rawReport.domains?.find(
            (domain) => purifyDomain(domain.name) === purifyDomain(selectedDomain),
          )?.campaigns || [];
        const goalToCalculateChanceToWin = this.goalConversionsPerDomain[tab];
        return campaignsToCalculateChanceToWin
          .filter((c) => c.variants.length > 1)
          .map((campaign) => {
            return {
              id: campaign.id,
              variants: campaign.variants.map((variant) => {
                const conversion =
                  goalToCalculateChanceToWin?.[campaign.id]?.variants?.[variant.id]?.goalCount;
                return {
                  id: variant.id,
                  impressions: variant.visitorCount,
                  conversions: {
                    allType: conversion?.allType,
                    desktop: conversion?.desktop,
                    mobile: conversion?.mobile,
                  },
                };
              }),
            };
          });
      },
      async fetchCampaignReport() {
        this.loadingCampaignReport = true;
        this.campaignReportError = false;
        try {
          this.rawReport = (
            await this.$axios.get(
              `/reports/campaigns?date_start=${this.startDate}&date_end=${this.endDate}`,
            )
          ).data;
        } catch (e) {
          this.campaignReportError = true;
          track('analyticsError', {
            type: 'campaignReportError',
            startDate: this.startDate,
            endDate: this.endDate,
          });
        }
        this.loadingCampaignReport = false;

        if (!this.chanceToWin[this.selectedDomain._id]?.campaignConversion) {
          this.mappedReportCampaignsForChanceToWin = this.mapReportForChanceToWin();
          this.getChanceToWin(
            this.mappedReportCampaignsForChanceToWin,
            this.selectedDomain._id,
            'campaignConversion',
          );
        }
      },
      reset() {
        this.topLevelDomainData = {};
        this.chanceToWin = {};
        this.goalConversions = {};
      },
      async dateRangeChange(selectedDateRange, event) {
        this.filter.dateRange = event.key;

        if (
          selectedDateRange.interval.from !== event.interval.from ||
          selectedDateRange.interval.to !== event.interval.to
        ) {
          if (event.key !== 'custom') {
            this.filter.startDate = null;
            this.filter.endDate = null;
          }

          if (event.key === 'custom') {
            this.customRange = event;
            this.filter.startDate = moment(event.interval.from).format(this.requestDateFormat);
            this.filter.endDate = moment(event.interval.to).format(this.requestDateFormat);
          }

          this.reset();

          await Promise.all([this.fetchCampaignReport(), this.getTopLevelDomainData()]);

          if (this.selectedTab !== 'campaignConversion') {
            await this.getGoalTabData(this.selectedTab);
          }
        }
      },
      saveDefaultDomainToLS(domainId) {
        let defaultDomainsInAnalytics = lsRetrieve(this.DEFAULT_DOMAIN_IN_ANALYTICS_KEY);

        if (defaultDomainsInAnalytics?.[this.accountId]) {
          defaultDomainsInAnalytics[this.accountId] = domainId;
        } else {
          defaultDomainsInAnalytics = {
            ...defaultDomainsInAnalytics,
            [this.accountId]: domainId,
          };
        }
        lsStore(this.DEFAULT_DOMAIN_IN_ANALYTICS_KEY, defaultDomainsInAnalytics);
      },
      getDefaultDomainFromLS() {
        const defaultDomainsInAnalytics = lsRetrieve(this.DEFAULT_DOMAIN_IN_ANALYTICS_KEY);
        return defaultDomainsInAnalytics?.[this.accountId] || null;
      },
      async getGoals(domainId) {
        this.loadingGoals = true;
        this.goalsError = false;
        try {
          const {
            data: { goals },
          } = await this.$apollo.query({
            query: GET_GOALS,
            variables: {
              domainId,
            },
          });
          if (goals?.goals) {
            const defaultOrder = goals.goals.find((goal) => goal._id === 'default_purchase');
            const defaultAddToCart = goals.goals.find((goal) => goal._id === 'default_addToCart');
            const otherGoals = goals.goals
              .filter((goal) => !goal._id.startsWith('default'))
              .map((otherGoal) => {
                const { _id, name, rules } = otherGoal;
                return {
                  key: _id,
                  text: name,
                  isOrderGoal: rules.expressions.some((exp) => exp.type === 'eoo'),
                };
              });
            this.tabs = [
              ...(defaultOrder
                ? [
                    {
                      key: defaultOrder._id,
                      text: this.$t('conversionGoals.eventTypes.purchase'),
                      isOrderGoal: true,
                    },
                  ]
                : []),
              { key: 'campaignConversion', text: this.$t('analyticsPage.campaignConversion') },
              ...(defaultAddToCart
                ? [
                    {
                      key: defaultAddToCart._id,
                      text: this.$t('conversionGoals.eventTypes.addToCart'),
                      isOrderGoal: false,
                    },
                  ]
                : []),
              ...otherGoals,
            ];
            if (defaultOrder) {
              this.filter.tab = defaultOrder._id;
            }
            this.loadingGoals = false;
          }
        } catch (e) {
          this.loadingGoals = false;
          this.goalsError = true;
          track('analyticsError', {
            type: 'goalsError',
            domainId,
          });
          return [];
        }
      },
      async getGoal(selectedTab) {
        this.loadingGoal = true;
        this.goalError = false;
        try {
          const {
            data: {
              goal: { campaigns },
            },
          } = await this.$apollo.query({
            query: GET_GOAL_GROUPED_BY_DEVICE_TYPE,
            variables: {
              domainId: this.selectedDomain._id,
              goalId: selectedTab,
              startDate: this.startDate,
              endDate: this.endDate,
            },
          });

          const mappedGoal = campaigns.reduce((mappedGoals, campaign) => {
            const { campaignId, goalCount, variants } = campaign;
            mappedGoals[campaignId] = {
              goalCount,
              variants: variants.reduce((mappedVariants, variant) => {
                const { variantId, goalCount } = variant;
                mappedVariants[variantId] = { goalCount };
                return mappedVariants;
              }, {}),
            };
            return mappedGoals;
          }, {});

          if (!this.goalConversions[this.selectedDomain._id]) {
            this.goalConversions[this.selectedDomain._id] = {};
          }

          const goalConversionsForSelectedDomain = {
            ...this.goalConversions[this.selectedDomain._id],
            [selectedTab]: mappedGoal,
          };
          this.goalConversions = {
            ...this.goalConversions,
            [this.selectedDomain._id]: goalConversionsForSelectedDomain,
          };
        } catch (e) {
          this.goalError = true;
        }
        this.loadingGoal = false;
      },
      async getGoalTabData(selectedTab) {
        await this.getGoal(selectedTab);

        const mappedCampaigns = this.mapReportAndGoalForChanceToWin(
          this.selectedDomain._id,
          selectedTab,
        );
        this.getChanceToWin(mappedCampaigns, this.selectedDomain._id, selectedTab);
      },
      async changeTab($event) {
        this.filter.tab = $event.tab;
        if (this.selectedTab !== 'campaignConversion') {
          if (!this.goalConversions[this.selectedDomain._id]?.[this.selectedTab]) {
            await this.getGoalTabData(this.selectedTab);
          }
        } else if (this.selectedTab === 'campaignConversion') {
          if (!this.chanceToWin[this.selectedDomain._id]?.[this.selectedTab]) {
            this.getChanceToWin(
              this.mappedReportCampaignsForChanceToWin,
              this.selectedDomain._id,
              this.selectedTab,
            );
          }
        }
      },
      updateBrowserUrl() {
        const query = {
          tab: this.filter.tab,
          domain: this.filter.domainId,
          device: this.filter.device,
          dateRange: this.filter.dateRange,
          ...(this.filter.dateRange === 'custom'
            ? { startDate: this.startDate, endDate: this.endDate }
            : {}),
        };

        const params = new URLSearchParams(query).toString();
        const accountId = this.$route.params.userId;
        window.history.replaceState({}, 'om-reports', `/${accountId}/analytics?${params}`);
      },
      learnMore() {
        const link =
          this.getLocale === 'en'
            ? 'https://support.optimonk.com/hc/en-us/articles/5098278551186-Campaign-Analytics'
            : 'https://support.optimonk.hu/hc/hu/articles/14402942764562-Kamp%C3%A1ny-analitika';

        window.open(link, '_blank');
      },
    },
  };
</script>
<style lang="sass" scoped>
  @import '../sass/variables/_colors'
  .total-analytics
    position: relative
    z-index: 11

    @media screen and (min-width: 1200px)
      margin-bottom: 2rem

    &-container
      border: 1px solid $om-gray-300
      padding: 1rem 1.25rem
      border-radius: 5px
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1)
      color: $om-gray-800
      height: 100%
      display: flex
      flex-wrap: wrap
      flex-direction: column
    &-title
      font-size: 15px
      line-height: 1.33
      color: $om-gray-700
      width: 100%
      .title
        border-bottom: 1px dashed $om-gray-500
    &-total
      font-size: 24px
      font-weight: 700
      line-height: 1
      margin-top: 6px
      align-self: flex-start
      width: 100%

  @media screen and (max-width: 1400px)
    .total-analytics
      >div
        margin-bottom: 20px

  @media screen and (max-width: 1599px)
    .total-analytics
      &-title
        font-size: 13px
      &-total
        font-size: 15px

        .secondary-currency
          font-size: 18px

  .favicon
    border: 1px solid $om-gray-300
    border-radius: 50%
    height: 2rem
    width: 2rem
    padding: 1px


  .selectors
    height: 3.5rem
    .domain-selector
      ::v-deep .select-wrapper
        margin-left: 1px

        .select-toggle
          top: 2px
          background: transparent
      ::v-deep .form-control
        border: none
        padding-left: 0.5rem
        width: min-content

        .select-selection
          line-height: 2rem
          font-size: 1.5rem
          font-weight: 700

      &.select-dropdown-open
        ::v-deep.select-wrapper
          margin-left: 0

        ::v-deep .select-is-focused
          box-shadow: none
          border-radius: 4px
          border: 1px solid transparent
          background: $om-gray-200
          padding-left: 0.5rem

          .select-input
            line-height: 2rem
            font-size: 1.5rem
            font-weight: 700

    ::v-deep .select .select-label
      padding-top: 0

  .top-level-error,
  .ga4-notification
    align-items: center
    width: 100%
    border: 1px solid $om-gray-300
    border-radius: 4px

    svg
      height: 24px
      width: 24px
      margin-right: 10px
      flex-shrink: 0

  .analytics-error-message
    display: block
    text-align: center
    margin: auto
    line-height: 32px
    max-width: 510px
    color: $om-gray-800

  .general-error
    margin-top: 2rem
    padding-top: 2.5rem

  .no-code-detected
    background: #FFF7F2
    padding: 55px 40px
    justify-content: space-between
    align-items: center
    position: relative
    overflow: hidden

    .right-shape
      position: absolute
      z-index: 1
      bottom: 0
      right: 0

    .explanation
      flex: 0 0 31%
      z-index: 2
      margin-right: 2.5rem
      .title
        font-size: 2rem
        font-weight: 700
        line-height: 2.5rem
        color: $om-gray-800

      .description
        font-size: 1.125rem
        font-weight: 400
        line-height: 1.75rem
        color: $om-gray-700

    .image
      flex: 0 1 70%
      z-index: 2
      box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.04), 0 0 20px 0 rgba(0, 0, 0, 0.1)

      img
        width: 100%
</style>
